<template>
  <v-container class="app-context" fluid>
    <div class="context flex">
      <div class="hospital-list fill-width-list">
        <v-card elevation="0" class="mr-5">
          <v-row>
            <v-col cols="auto">
              <v-card-title class="pl-0 reform_primary--text req1-title-text">
                {{ titleName }}
              </v-card-title>
            </v-col>
            <v-col cols="auto">
              <v-card-title>
                <div class="notification req1-notification">
                  <div class="patient-count req1-patient-count">
                    <span
                      class="text-h6 text-name-span req1-patient-text-name"
                      v-text="textName"
                    ></span>
                    <span
                      v-if="currentTab === 1"
                      class="text-h6 text-name-span req1-patient-text"
                      >{{ listStatus.totalDataCnt }}명</span
                    >
                    <span
                      v-else
                      class="text-h6 text-name-span req1-patient-text"
                      >{{ listStatus.totalDataCnt }}명</span
                    >
                    <h1 v-if="currentTab === 1">||</h1>
                    <span
                      class="text-h6"
                      v-text="textTempName"
                      v-if="currentTab === 1"
                    ></span>
                    <span
                      class="text-h6 req1-patient-text"
                      v-if="currentTab === 1"
                      >{{ listStatus.tempCount }}명</span
                    >
                  </div>
                </div>
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
        <div class="pa-0 ma-0" v-if="list.length > 0 && !isChildLoading">
          <v-container fluid>
            <v-row justify="space-between" no-gutters>
              <v-col cols="12" lg="4" class="pr-1 mt-1">
                <v-row no-gutters>
                  <!-- 텍스트필드 1번 -->
                  <v-col>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      @click="pickerOffset(0)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          ref="dateInputFirst"
                          v-model="conditional_date_array[0]"
                          hide-details
                          outlined
                          dense
                          rounded
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        calss="picker1"
                        color="#146F83"
                        header-color="#146F83"
                        v-model="picker_first"
                        @change="click_picker(0)"
                        locale="kr"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- ~ -->
                  <v-col cols="auto" class="pr-1 pl-1 pt-2 font-weight-bold">
                    ~
                  </v-col>
                  <!-- 텍스트필드 2번 -->
                  <v-col>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      @click="pickerOffset(1)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          ref="dateInputFirst"
                          v-model="conditional_date_array[1]"
                          hide-details
                          outlined
                          dense
                          rounded
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        calss="picker1"
                        color="#146F83"
                        header-color="#146F83"
                        v-model="picker_second"
                        @change="click_picker(1)"
                        locale="kr"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" class="pr-1 mt-1">
                <v-row no-gutters justify="space-between">
                  <!-- btn 당월 -->
                  <v-col class="pr-1">
                    <v-btn
                      width="100%"
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      height="40px"
                      @click="conditional_date('month')"
                      class="btn-font"
                    >
                      당월
                    </v-btn>
                  </v-col>
                  <!-- btn 1달전 -->
                  <v-col class="pr-1">
                    <v-btn
                      width="100%"
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      height="40px"
                      @click="conditional_date('month_back')"
                      class="btn-font"
                    >
                      1달전
                    </v-btn>
                  </v-col>
                  <!-- btn 3달전 -->
                  <v-col class="pr-1">
                    <v-btn
                      width="100%"
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      height="40px"
                      @click="conditional_date('3month_back')"
                      class="btn-font"
                    >
                      3달전
                    </v-btn>
                  </v-col>
                  <!-- btn 전체 -->
                  <v-col class="pr-0">
                    <v-btn
                      width="100%"
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      height="40px"
                      @click="conditional_date('all')"
                      class="btn-font"
                    >
                      전체
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" class="mt-1 search-col">
                <v-row no-gutters justify="end" class="search-col-row">
                  <v-col cols="auto" class="search-col-btn">
                    <v-menu bottom right offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          rounded
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                          height="40px"
                          width="120px"
                          class="mr-1"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, i) in items"
                          :key="i"
                          @click="type = item"
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="auto" class="search-col-btn">
                    <v-btn
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      width="120px"
                      height="40px"
                      @click="clickSearch()"
                    >
                      검색
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <div class="scroll-body">
            <div class="scroll-content">
              <div v-if="list.length > 0 && !isChildLoading">
                <v-row no-gutters style="font-size: 19px; color: gray">
                  <v-col cols="6">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col class="grey--text" cols="2">
                          <span> 날짜 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 이름 </span>
                        </v-col>
                        <v-col class="grey--text" cols="1">
                          <span> 성별 </span>
                        </v-col>
                        <v-col class="grey--text" cols="1">
                          <span> 나이 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 진단명 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 진단코드 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 의사명 </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="6">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col class="grey--text" cols="3">
                          <span> 병원명 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 내원현황 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 회신현황 </span>
                        </v-col>
                        <v-col class="grey--text" cols="1">
                          <span> 되의뢰 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="1">
                          <span> 첨부 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="1">
                          <span> 메모 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="2">
                          <span> 보내기 </span>
                        </v-col>
                      </v-row>
                    </v-container>
                    <span style="border-bottom: 1px solid lightgray"> </span>
                  </v-col>
                </v-row>
                <v-row
                  style="font-size: 18px; border-bottom: 1px solid lightgray"
                  v-for="(listItem, index) in list"
                  :key="index + 'A'"
                  class="receive-patient-list"
                  @click="
                    goDetail($event, listItem.referId, index, listItem.rcvMemo)
                  "
                  no-gutters
                >
                  <v-col cols="6">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col cols="2">
                          {{ daySet(listItem.referDt) }}
                        </v-col>
                        <v-col cols="2">
                          {{ listItem.patNm }}
                        </v-col>
                        <v-col cols="1">
                          {{ getGender(listItem.patBirth) }}
                        </v-col>
                        <v-col cols="1">
                          {{ getAge(listItem.patBirth) }}세
                        </v-col>
                        <v-col cols="2">
                          {{ listItem.diagNm }}
                        </v-col>
                        <v-col cols="2">
                          {{ listItem.diagCd }}
                        </v-col>
                        <v-col cols="2">
                          {{ listItem.sndDrNm }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="6">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col cols="3">
                          {{
                            listItem.sndHospNm.length > 10
                              ? listItem.sndHospNm.substr(0, 8) + ".."
                              : listItem.sndHospNm
                          }}
                        </v-col>
                        <v-col cols="2">
                          {{
                            list[index].visitYn === "Y" ? "내원 완료" : "미내원"
                          }}
                        </v-col>
                        <v-col cols="2">
                          {{
                            list[index].rcvYn === "Y" ? "회신 완료" : "미회신"
                          }}
                        </v-col>
                        <v-col cols="1">
                          {{ list[index].rereferYn === "Y" ? "되의뢰" : "" }}
                        </v-col>
                        <v-col cols="1" class="text-center">
                          <img
                            v-if="
                              listItem.referAttchId !== '' &&
                              listItem.referAttchId !== null
                            "
                            src="@/assets/images/icons/attach_file_black_24dp.svg"
                            style="margin-left: 10px"
                          />
                        </v-col>
                        <v-col cols="1" class="text-center">
                          <span
                            style="cursor: pointer"
                            @click.stop="openMemo(index)"
                          >
                            <img
                            v-if="listItem.rcvMemo === null || listItem.rcvMemo === ''"
                            src="@/assets/images/icons/note_alt_black_24dp.svg"
                            />
                            <img
                              v-else
                              src="@/assets/images/icons/note_alt_green_24dp.svg"
                            />
                          </span>
                        </v-col>
                        <v-col cols="2" class="text-center">
                          <span v-if="list[index].inboxYn === 'N'">
                            <input
                              v-model="list_check[index]"
                              id="memo"
                              type="checkbox"
                              true-value="N"
                              false-value="Y"
                              @change="check_inbox_number()"
                            />
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>

                    <span style="border-bottom: 1px solid lightgray"> </span>
                  </v-col>
                  <v-container fluid v-show="listItem.openMemo === true">
                    <v-row>
                      <v-col cols="11">
                        <v-text-field
                          :disabled="
                            list[index].cancelYn === 'N' ? false : true
                          "
                          :id="'memo' + index + 'B'"
                          v-model="listItem.rcvMemo"
                          label="  메모는 이곳에 저장됩니다."
                          clearable
                          solo
                          flat
                          background-color="white"
                          @keyup.enter="focusControl()"
                          style="
                            width: 100%;
                            height: 50px;
                            font-size: 14px;
                            display: inline-block;
                            border-radius: 10px 10px 10px 10px;
                          "
                        />
                      </v-col>
                      <v-col cols="1" class="pt-5">
                        <span
                          id="memo"
                          style="
                            position: absolute;
                            width: 70px;
                            height: 35px;
                            background-color: #146f83;
                            cursor: pointer;
                            border-radius: 90px 90px 90px 90px;
                          "
                        >
                          <p
                            id="memo"
                            @click="
                              list[index].cancelYn === 'N'
                                ? saveMemo(
                                    listItem.referId,
                                    listItem.rcvMemo,
                                    'RCV'
                                  )
                                : null
                            "
                            style="
                              color: #ffffff;
                              font-weight: bold;
                              text-align: center;
                              line-height: 35px;
                              font-size: 17px;
                            "
                          >
                            저장
                          </p>
                        </span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </div>
            </div>
          </div>
          <v-row align="center" class="mt-5" justify="space-between">
            <v-col
              cols="auto"
              class="text-left"
              v-show="$vuetify.breakpoint.mdAndUp"
            >
              <v-btn
                class="text-subtitle-1 font-weight-bold"
                dark
                large
                rounded
                elevation="0"
                @click="getRcvExel()"
                color="reform_primary"
                width="120px"
              >
                엑셀 다운로드
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center pagination-wrap pa-0 ma-0"
            >
              <p
                class="navigation-act prev"
                v-if="listStatus.first > 5"
                @click="navigatorAct('prev')"
              >
                <!-- Prev -->
                <v-icon> mdi-chevron-left </v-icon>
              </p>
              <ul id="pagination" class="pa-0">
                <li
                  v-for="(
                    pagination, index
                  ) in listStatus.currentPaginationList"
                  :key="index + 'j'"
                  @click="loadReferList(pagination, index)"
                  :class="
                    (listStatus.currentPage - 1) % listStatus.pageCount ===
                    index
                      ? 'active'
                      : ''
                  "
                >
                  <p>{{ pagination }}</p>
                </li>
              </ul>
              <p
                class="navigation-act next"
                v-if="
                  listStatus.next > 5 &&
                  listStatus.pageCount < listStatus.totalPage &&
                  listStatus.last !== listStatus.totalPage
                "
                @click="navigatorAct('next')"
              >
                <!-- Next -->
                <v-icon> mdi-chevron-right </v-icon>
              </p>
            </v-col>
            <v-col
              cols="auto"
              class="text-left"
              v-show="$vuetify.breakpoint.smAndDown"
            >
              <v-btn
                class="text-subtitle-1 font-weight-bold"
                dark
                large
                rounded
                elevation="0"
                @click="getRcvExel()"
                color="reform_primary"
                width="120px"
              >
                엑셀 다운로드
              </v-btn>
            </v-col>
            <v-col cols="auto" class="text-right">
              <v-btn
                class="text-subtitle-1 font-weight-bold"
                dark
                large
                rounded
                elevation="0"
                @click="clickInBox()"
                color="reform_primary"
              >
                수신환자 리스트로 보내기
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="pa-0 ma-0" v-else>
          <v-container>
            <v-row justify="space-between" no-gutters>
              <v-col cols="12" lg="4" class="pr-1 mt-1">
                <v-row no-gutters>
                  <!-- 텍스트필드 1번 -->
                  <v-col>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      @click="pickerOffset(0)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          ref="dateInputFirst"
                          v-model="conditional_date_array[0]"
                          hide-details
                          outlined
                          dense
                          rounded
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        calss="picker1"
                        color="#146F83"
                        header-color="#146F83"
                        v-model="picker_first"
                        @change="click_picker(0)"
                        locale="kr"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- ~ -->
                  <v-col cols="auto" class="pr-1 pl-1 pt-2 font-weight-bold">
                    ~
                  </v-col>
                  <!-- 텍스트필드 2번 -->
                  <v-col>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      @click="pickerOffset(1)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          ref="dateInputFirst"
                          v-model="conditional_date_array[1]"
                          hide-details
                          outlined
                          dense
                          rounded
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        calss="picker1"
                        color="#146F83"
                        header-color="#146F83"
                        v-model="picker_second"
                        @change="click_picker(1)"
                        locale="kr"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" class="pr-1 mt-1">
                <v-row no-gutters justify="space-between">
                  <!-- btn 당월 -->
                  <v-col class="pr-1">
                    <v-btn
                      width="100%"
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      height="40px"
                      @click="conditional_date('month')"
                      class="btn-font"
                    >
                      당월
                    </v-btn>
                  </v-col>
                  <!-- btn 1달전 -->
                  <v-col class="pr-1">
                    <v-btn
                      width="100%"
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      height="40px"
                      @click="conditional_date('month_back')"
                      class="btn-font"
                    >
                      1달전
                    </v-btn>
                  </v-col>
                  <!-- btn 3달전 -->
                  <v-col class="pr-1">
                    <v-btn
                      width="100%"
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      height="40px"
                      @click="conditional_date('3month_back')"
                      class="btn-font"
                    >
                      3달전
                    </v-btn>
                  </v-col>
                  <!-- btn 전체 -->
                  <v-col class="pr-0">
                    <v-btn
                      width="100%"
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      height="40px"
                      @click="conditional_date('all')"
                      class="btn-font"
                    >
                      전체
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="4" class="mt-1 search-col">
                <v-row no-gutters justify="end" class="search-col-row">
                  <v-col cols="auto" class="search-col-btn">
                    <v-menu bottom right offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          outlined
                          rounded
                          color="grey darken-2"
                          v-bind="attrs"
                          v-on="on"
                          height="40px"
                          width="120px"
                          class="mr-1"
                        >
                          <span>{{ typeToLabel[type] }}</span>
                          <v-icon right> mdi-menu-down </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, i) in items"
                          :key="i"
                          @click="type = item"
                        >
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                  <v-col cols="auto" class="search-col-btn">
                    <v-btn
                      color="reform_primary"
                      rounded
                      elevation="0"
                      dark
                      width="120px"
                      height="40px"
                      @click="clickSearch()"
                    >
                      검색
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <div class="scroll-body">
            <div class="scroll-content">
              <div>
                <v-row no-gutters style="font-size: 19px; color: gray">
                  <v-col cols="6">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col class="grey--text" cols="2">
                          <span> 날짜 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 이름 </span>
                        </v-col>
                        <v-col class="grey--text" cols="1">
                          <span> 성별 </span>
                        </v-col>
                        <v-col class="grey--text" cols="1">
                          <span> 나이 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 진단명 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 진단코드 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 의사명 </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="6">
                    <v-container fluid>
                      <v-row no-gutters>
                        <v-col class="grey--text" cols="3">
                          <span> 병원명 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 내원현황 </span>
                        </v-col>
                        <v-col class="grey--text" cols="2">
                          <span> 회신현황 </span>
                        </v-col>
                        <v-col class="grey--text" cols="1">
                          <span> 되의뢰 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="1">
                          <span> 첨부 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="1">
                          <span> 메모 </span>
                        </v-col>
                        <v-col class="grey--text text-center" cols="2">
                          <span> 보내기 </span>
                        </v-col>
                      </v-row>
                    </v-container>
                    <span style="border-bottom: 1px solid lightgray"> </span>
                  </v-col>
                  <v-col cols="12" class="text-center logo-wrap-col">
                    <div class="item">
                      <div class="logo-wrap">
                        <img src="@/assets/images/logo_unplugged.svg" />
                      </div>
                      <h4>조회결과 없음</h4>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
        <div class="spinner" v-if="isChildLoading">
          <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
            <beat-loader
              :loading="isChildLoading"
              :color="loader.color"
              :size="loader.size"
            ></beat-loader>
          </transition>
        </div>
      </div>
      <button id="focusControl"></button>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import BeatLoader from "vue-spinner/src/BeatLoader";

export default {
  components: {
    DefaultPopup,
    BeatLoader,
  },
  data() {
    return {
      btnwidth: 80,
      items: ["전체", "회신 완료", "내원 완료", "되의뢰 완료"],
      titleName: "전체 수신 환자 리스트",
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      type: "전체",
      typeToLabel: {
        전체: "전체",
        "회신 완료": "회신 완료",
        "내원 완료": "내원 완료",
        "되의뢰 완료": "되의뢰 완료",
      },
      currentTab: "",
      searchOpt: {
        receiveOrder: 0, // default 옵션,
        recordsPerPage: "", // 한번에 불러올 갯수
      },
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        tempCount: null,
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        sendCount: null,
        cancelCount: null,
        /**
         * totalDataCnt: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px",
      },
      list: [],
      list_check: [],
      setCount: false,
      textName: "의뢰 받은 환자",
      textTempName: "",
      patNm: "",
      conditional_date_array: [],
      conditional_condition: 0,
      picker_first: "",
      picker_second: "",
      menu1: false,
      menu2: false,
      picker_first_offset: [0, 0],
      picker_second_offset: [0, 0],
      checkCount: 0,
    };
  },
  created() {
    window.scrollTo(0, 0);
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    // }

    this.initStatement();
  },
  mounted() {
    if (this.GET_LIST_ORDER !== null) {
      this.sideMenuCall(this.GET_LIST_ORDER);
    }

    this.reload();
  },
  computed: {
    ...mapGetters("util", ["GET_EXEL_DATA"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
    ...mapGetters("referList", [
      "GET_SEND_LIST",
      "GET_RECEIVE_LIST",
      "GET_LIST_ORDER",
      "GET_RES_DOCTOR_DATA",
      "GET_REQ_DOCTOR_DATA",
      "GET_REFER_DETAIL",
    ]),
    daySet() {
      return (day) => {
        if (day !== undefined) {
          let dano = "";
          dano += day.substr(0, 4);
          dano += "/";
          dano += day.substr(4, 2);
          dano += "/";
          dano += day.substr(6, 2);
          return dano;
        }
      };
    },
    ...mapGetters("request", ["GET_REFER_ID", "GET_REPLY_STATUS"]),
    getGender() {
      return (value) => {
        if (value !== null && value !== "" && value !== undefined) {
          //console.log("gender-Value", value);
          const genderCount = value.substr(7, 8);
          if (genderCount === "2" || genderCount === "4") {
            return "여";
          } else if (genderCount === "1" || genderCount === "3") {
            return "남";
          } else if (
            genderCount === "5" ||
            genderCount === "6" ||
            genderCount === "7" ||
            genderCount === "8"
          ) {
            return "외국인";
          }
        } else {
          return null;
        }
      };
    },
    getAge() {
      return (value) => {
        if (value !== null && value !== "" && value !== undefined) {
          //console.log("Age-value", value);
          let currentAge;
          const ageCount = value.substr(0, 2);
          //console.log("Age-count", ageCount);
          const now = new Date().getFullYear();
          //console.log("Age-now", now);
          const item = String(now).substr(2, 4);
          //console.log("calcu-now", item);
          if (Number(ageCount) >= Number(item)) {
            const plusSu = 100 - Number(ageCount);
            //console.log("plusSu", plusSu);
            currentAge = Number(item) + Number(plusSu);
          } else {
            currentAge = Number(item) - Number(ageCount);
          }
          return currentAge;
        } else {
          return null;
        }
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapMutations("request", ["SET_REFER_ID", "SET_REPLY_STATUS"]),
    ...mapMutations("util", ["SET_LIST_DOC_INFO"]),
    ...mapActions("referList", [
      "BRING_SEND_LIST",
      "RECEIVE_SEND_LIST_ALL",
      "REQ_DOCTOR_LIST",
      "RES_DOCTOR_LIST",
      "SAVE_MEMO",
      "SAVE_INBOX",
      "INBOX_RESTORE",
    ]),
    ...mapActions("join", ["FETCH_BASIC_TARGET"]),
    ...mapMutations("referList", ["SET_REFER_DETAIL"]),
    ...mapActions("util", ["BRING_RECV_EXEL", "BRING_SEND_EXEL"]),
    pickerOffset(picker) {
      if (picker === 0) {
        //console.log(this.$refs.dateInputFirst);
        const clientRect = this.$refs.dateInputFirst.getBoundingClientRect();
        //console.log(clientRect.top);
        //console.log(clientRect.left);

        this.picker_first_offset = [clientRect.left, clientRect.top];

        //console.log(this.$refs.menu1.styles);
      } else if (picker === 1) {
        //console.log(this.$refs.dateInputSecond);
        const clientRect = this.$refs.dateInputSecond.getBoundingClientRect();
        //console.log(clientRect.top);
        //console.log(clientRect.left);
        this.picker_second_offset = [clientRect.left, clientRect.top];
      }
    },
    async clickSearch() {
      //console.log(this.conditional_date_array);
      if (this.type === "전체") {
        this.conditional_condition = 0;
      } else if (this.type === "회신 완료") {
        this.conditional_condition = 1;
      } else if (this.type === "내원 완료") {
        this.conditional_condition = 2;
      } else if (this.type === "되의뢰 완료") {
        this.conditional_condition = 3;
      }

      //console.log(this.conditional_condition);
      /*console.log(
        this.$moment(this.conditional_date_array[1]).isAfter(
          this.conditional_date_array[0]
        )
      );*/
      if (
        this.conditional_date_array[0] === "전체" &&
        this.conditional_date_array[1] === "전체"
      ) {
        this.conditional_date_array[0] = null;
        this.conditional_date_array[1] = null;
        this.initStatement();
        this.reload();
      } else if (
        this.conditional_date_array[0] !== undefined &&
        this.conditional_date_array[0] !== null &&
        this.conditional_date_array[1] !== undefined &&
        this.conditional_date_array[1] !== null
      ) {
        if (
          this.$moment(this.conditional_date_array[1]).isAfter(
            this.conditional_date_array[0]
          )
        ) {
          this.reload();
        } else {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content =
            "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content =
          "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    click_picker(str) {
      if (str === 0) {
        //console.log(this.picker_first);
        const [year, month, day] = this.picker_first.split("-");
        //console.log(`${year}${month}${day}`);
        this.conditional_date_array[0] = `${year}${month}${day}`;
      } else if (str === 1) {
        //console.log(this.picker_second);
        const [year, month, day] = this.picker_second.split("-");
        //console.log(`${year}${month}${day}`);
        this.conditional_date_array[1] = `${year}${month}${day}`;
      }
    },
    async conditional_date(type) {
      if (type === "all") {
        this.conditional_date_array = ["전체", "전체"];
      } else if (type === "month") {
        this.conditional_date_array = [
          this.$moment().subtract(0, "M").format("YYYYMM") + "01",
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "month_back") {
        this.conditional_date_array = [
          this.$moment().subtract(1, "M").format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "3month_back") {
        this.conditional_date_array = [
          this.$moment().subtract(3, "M").format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      }
    },
    check_inbox_number() {
      var list_for_trans = [];
      list_for_trans = JSON.parse(JSON.stringify(this.list));

      for (var i = 0; i < list_for_trans.length; i++) {
        list_for_trans[i].inboxYn = "Y";

        if (this.list_check[i] !== null && this.list_check[i] !== undefined) {
          list_for_trans[i].inboxYn = this.list_check[i];
        }
      }

      for (var i = 0; i < list_for_trans.length; i++) {
        if (list_for_trans[i].inboxYn === "Y") {
          list_for_trans.splice(i, 1);
          i--;
        }
      }
      this.checkCount = i;
    },
    async clickInBox() {
      //console.log(this.list_check);
      //console.log(this.list);
      var list_for_trans = [];
      list_for_trans = JSON.parse(JSON.stringify(this.list));

      for (var i = 0; i < list_for_trans.length; i++) {
        list_for_trans[i].inboxYn = "Y";

        if (this.list_check[i] !== null && this.list_check[i] !== undefined) {
          list_for_trans[i].inboxYn = this.list_check[i];
        }
      }

      for (var i = 0; i < list_for_trans.length; i++) {
        if (list_for_trans[i].inboxYn === "Y") {
          list_for_trans.splice(i, 1);
          i--;
        }
      }
      console.log("list_for_trans : ", list_for_trans);

      var referIdTestBoo = /^[0-9]{8,14}$/;
      var referIdBoo = false;
      for (var i = 0; list_for_trans.length > i; i++) {
        if (
          list_for_trans[i].referId === null ||
          list_for_trans[i].referId === undefined
        )
          list_for_trans[i].referId = "";

        if (!referIdTestBoo.test(list_for_trans[i].referId)) referIdBoo = true;
      }

      if (referIdBoo) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "리스트를 불러올 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      try {
        await this.INBOX_RESTORE(list_for_trans).then(() => {
          this.SET_POPUP(true);
          this.popupSet.title = "진료의뢰서 ReStore 완료";
          this.popupSet.content =
            "진료의뢰서를 수신환자 리스트에 저장 완료하였습니다.\n수신환자 리스트(Inbox)에서 확인 하실 수 있습니다.";
          this.popupSet.popType = "InBox_Restore";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.nextLink = "/v/mypage/request/1";
          this.popupSet.destination = true;
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async saveMemo(id, memo, str) {
      var referParam = {};
      if (str === "RCV") {
        referParam = {
          referId: id,
          rcvMemo: memo,
          /** completed */
        };
      } else if (str === "SND") {
        referParam = {
          referId: id,
          sndMemo: memo,
          /** completed */
        };
      }

      //console.log("referParam : ", referParam);

      //유효성 검사
      //1. 진단의뢰번호
      if (referParam.referId === null || referParam.referId === undefined)
        referParam.referId = "";

      var referIdBoo = /^[0-9]{8,14}$/;

      if (!referIdBoo.test(referParam.referId)) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "리스트를 불러올 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 진단의뢰 메모
      if (referParam.rcvMemo === null || referParam.rcvMemo === undefined)
        referParam.rcvMemo = "";

      if (referParam.rcvMemo.length < 0 || referParam.rcvMemo.length > 200) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = "200자 이내로 메모를 작성해주시기 바랍니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      try {
        await this.SAVE_MEMO(referParam).then(() => {
          //console.log("done");
          this.SET_POPUP(true);
          this.popupSet.title = "메모 저장 완료";
          this.popupSet.content = "리스트 메모 저장이 완료되었습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getRcvExel() {
      try {
        await this.BRING_RECV_EXEL().then(() => {
          //console.log(this.GET_EXEL_DATA.fileName);

          const url =
            "https://www.drrefer.net/api/excels/" + this.GET_EXEL_DATA.fileName;
          //console.log("called_excels_url", url);

          window.open(url, "_blank");
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getSendExel() {
      try {
        await this.BRING_SEND_EXEL().then(() => {
          //console.log(this.GET_EXEL_DATA.fileName);

          const url =
            "https://www.drrefer.net/api/excels/" + this.GET_EXEL_DATA.fileName;
          //console.log("called_excels_url", url);

          window.open(url, "_blank");
        });
      } catch (error) {
        console.log(error);
      }
    },
    phoneFomatter(number) {
      if (number !== "" && number !== null) {
        let formatNum = "";
        if (number.length == 11) {
          formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
        } else if (number.length == 8) {
          formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
        } else {
          //console.log("핵검증", number.indexOf("02"));
          if (number.indexOf("02") == 0) {
            if (number.length === 9) {
              formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
            } else {
              formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
            }
          } else {
            formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
          }
        }
        return formatNum;
      }
    },
    async moveRequest(param, index) {
      //console.log("채소재료", param, "-", index);
      await this.FETCH_BASIC_TARGET(param).then(() => {
        const doctorInfo = {
          drId: this.GET_BASIC.drId,
          receive: this.GET_BASIC.hospNm,
          doctor: this.GET_BASIC.drNm,
          referId: null,
          proObj: this.list[index].proObj,
        };
        this.SET_REPLY_STATUS(doctorInfo);
        this.$router.push("/v/request/requestDocument");
      });
    },
    writeMessage(item) {
      //console.log(item);
      this.$refs.appheader.callMessage(item);
    },
    handleGoback() {
      if (this.setCount) {
        this.$router.push("/").catch(() => {});
      } else {
        history.go(1);
      }
      this.setCount = true;
      // 클로즈 함수 구현
    },
    initStatement() {
      //console.log("리퍼아이디 잔여물", this.GET_REFER_ID); // null
      //console.log("리퍼디테일 잔여물", this.GET_REFER_DETAIL.visitYn); //undefined
      //console.log("CHECK_REPLY_STATUS", this.GET_REPLY_STATUS.referId);
      const initState = [];
      if (this.GET_REFER_ID !== null) {
        this.SET_REFER_ID(null);
      }
      if (Object.keys(this.GET_REFER_DETAIL).length !== 0) {
        this.SET_REFER_DETAIL(initState);
      }
      if (Object.keys(this.GET_REPLY_STATUS).length !== 0) {
        this.SET_REPLY_STATUS(initState);
      }
    },
    checkDataStep() {
      this.SET_POPUP(true);
      this.popupSet.title = "부가정보 입력요청";
      this.popupSet.content =
        "PR관리에서 부가정보를 입력하면 \n진료의뢰서를 수신할 수 있는 형식과 \n병원 소개 페이지가 자동으로 완성됩니다.";
      this.popupSet.popType = "warn";
      this.popupSet.cancelBtnText = "확인";
      this.sideSet.isWritedSearch = false;
      this.sideSet.isWritedPr = false;
    },
    initAuthInfo() {
      //console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    goPrView(drId, index) {
      const doctorInfo = {
        prevUrl: "/v/request/list",
        proObj: this.list[index].proObj,
      };
      this.SET_LIST_DOC_INFO(doctorInfo);
      //this.$router.push(`/v/pr/view/${drId}`);
      window.open(`/v/pr/view/${drId}`, "_blank");
    },
    initListStatus() {
      this.listStatus.totalDataCnt = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    async initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        /*console.log(
          "Ds",
          document.querySelector("#pagination").childNodes[k].childNodes[0]
            .textContent
        );*/
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadReferList(currentPage, order) {
      this.list = [];
      const list = [];
      this.SET_CHILD_LOADER(true);
      // this.initPaginationEffect();
      // if (this.listStatus.totalDataCnt >= 1) {
      //   document
      //     .querySelector("#pagination")
      //     .childNodes[order].classList.add("active");
      // }
      const searchOpt = {
        recordsPerPage: this.listStatus.dataOnPage,
        pageNo: currentPage,
      };

      // 유효성 검사

      //1. 페이지
      if (currentPage === null || currentPage === undefined) currentPage = "";

      var currentPageBoo = /^[0-9]{1,100}$/;

      if (!currentPageBoo.test(currentPage)) {
        this.SET_POPUP(true);
        this.popupSet.title = "페이지 요청 실패";
        this.popupSet.content = "페이지 요청에 오류가 발생하였습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      //2. 페이지 당 보여줄 수
      if (
        this.listStatus.dataOnPage === null ||
        this.listStatus.dataOnPage === undefined
      )
        this.listStatus.dataOnPage = "";

      var dataOnPageBoo = /^[0-9]{1,100}$/;

      if (!dataOnPageBoo.test(this.listStatus.dataOnPage)) {
        this.SET_POPUP(true);
        this.popupSet.title = "페이지 요청 실패";
        this.popupSet.content = "페이지 요청에 오류가 발생하였습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      if (this.searchOpt.receiveOrder === 0) {
        try {
          await this.RECEIVE_SEND_LIST_ALL(searchOpt).then(() => {
            for (let i = 0; i < this.GET_RECEIVE_LIST.list.length; i++) {
              list.push(this.GET_RECEIVE_LIST.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              // this.preParePagination();
            }, 100);

            // setTimeout(() => {
            //   if (this.listStatus.totalDataCnt >= 1) {
            //     document
            //       .querySelector("#pagination")
            //       .childNodes[order].classList.add("active");
            //   }
            // }, 200);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 받은 환자";
        this.textTempName = "";
      } else if (this.searchOpt.receiveOrder === 1) {
        //console.log("1232");
        try {
          //console.log("===========================================");
          //console.log(searchOpt);
          await this.BRING_SEND_LIST(searchOpt).then(() => {
            for (let i = 0; i < this.GET_SEND_LIST.list.length; i++) {
              list.push(this.GET_SEND_LIST.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              // this.preParePagination();
            }, 100);

            // setTimeout(() => {
            //   if (this.listStatus.totalDataCnt >= 1) {
            //     document
            //       .querySelector("#pagination")
            //       .childNodes[order].classList.add("active");
            //   }
            // }, 200);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 보낸 환자";
        this.textTempName = "임시 저장 환자";
      } else if (this.searchOpt.receiveOrder === 2) {
        //console.log("1233");
        try {
          await this.RES_DOCTOR_LIST(searchOpt).then(() => {
            for (let i = 0; i < this.GET_RES_DOCTOR_DATA.list.length; i++) {
              list.push(this.GET_RES_DOCTOR_DATA.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              // this.preParePagination();
            }, 100);

            // setTimeout(() => {
            //   if (this.listStatus.totalDataCnt >= 1) {
            //     document
            //       .querySelector("#pagination")
            //       .childNodes[order].classList.add("active");
            //   }
            // }, 200);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 받은 병원";
        this.textTempName = "";
      } else if (this.searchOpt.receiveOrder === 3) {
        //console.log("1234");
        try {
          await this.REQ_DOCTOR_LIST(searchOpt).then(() => {
            for (let i = 0; i < this.GET_REQ_DOCTOR_DATA.list.length; i++) {
              list.push(this.GET_REQ_DOCTOR_DATA.list[i]);
            }
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = list;
              this.listStatus.currentPage = order + 1;
              // this.preParePagination();
            }, 100);

            // setTimeout(() => {
            //   if (this.listStatus.totalDataCnt >= 1) {
            //     document
            //       .querySelector("#pagination")
            //       .childNodes[order].classList.add("active");
            //   }
            // }, 200);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
        this.textName = "의뢰 보낸 병원";
        this.textTempName = "";
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalDataCnt = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      //console.log("==========================================================");
      //console.log(this.listStatus);
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      //console.log("totalDataCnt", totalDataCnt);
      //console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      //console.log("totalPage", totalPage);
      //console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      //console.log("last : " + last);
      //console.log("first : " + first);
      //console.log("next :" + next);
      //console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalDataCnt: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    async calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        await this.$nextTick((_) => {
          this.loadReferList(this.listStatus.last, 4);
        });
        // setTimeout(() => {
        //   for (
        //     let k = 0;
        //     k < document.querySelector("#pagination").childElementCount;
        //     k++
        //   ) {
        //     /*console.log(
        //       "재료",
        //       document.querySelector("#pagination").childNodes[k].childNodes[0]
        //         .textContent
        //     );*/

        //     if (
        //       Number(
        //         document.querySelector("#pagination").childNodes[k]
        //           .childNodes[0].textContent
        //       ) === Number(this.listStatus.last)
        //     ) {
        //       document
        //         .querySelector("#pagination")
        //         .childNodes[k].classList.add("active");
        //     }
        //   }
        // }, 900);
      } else if (type === "next") {
        await this.loadReferList(this.listStatus.currentPage, 0).then(() => {
          // setTimeout(() => {
          //   document
          //     .querySelector("#pagination")
          //     .childNodes[0].classList.add("active");
          // }, 900);
        });
      } else {
        if (this.list.length > 0) {
          await this.$nextTick((_) => {
            const target = document.querySelectorAll(".receive-patient-list");
            if (target !== undefined) {
              function addElsePagination(currentPage) {
                setTimeout(() => {
                  if (currentPage !== null) {
                    if (
                      document.querySelector("#pagination") === null ||
                      document.querySelector("#pagination") === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === null ||
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ] === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    if (
                      document.querySelector("#pagination").childNodes[
                        currentPage - 1
                      ].classList === undefined
                    ) {
                      return addElsePagination(currentPage);
                    }
                    // document
                    //   .querySelector("#pagination")
                    //   .childNodes[currentPage - 1].classList.add("active");
                  } else {
                    return addElsePagination(currentPage);
                  }
                }, 100);
              }
              addElsePagination(this.listStatus.currentPage);
            }
          });
        }
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      // this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    sideMenuCall(order) {
      // this.$nextTick((_) => {
      //   //document.querySelector(".tabs__header").childNodes[order].click();
      // });
    },
    listReaded(order) {
      //document.querySelector(".tabs__header").childNodes[order].click();
    },
    async goDetail($event, referUrl, order, memo) {
      //console.log($event.target.id);

      if ($event.target.id.includes("memo")) {
        //console.log(memo);
      } else {
        this.SET_REFER_ID(referUrl);
        if (this.currentTab === 1) {
          if (
            this.list[order].rcvDrId === "" ||
            this.list[order].rcvDrId === null ||
            this.list[order].sndDrId === "" ||
            this.list[order].sndDrId === null ||
            this.list[order].diagNm === "" ||
            this.list[order].diagNm === null ||
            this.list[order].referConts === "" ||
            this.list[order].referConts === null ||
            this.list[order].rcvHospNm === "" ||
            this.list[order].rcvHospNm === null ||
            this.list[order].rcvDrNm === "" ||
            this.list[order].rcvDrNm === null ||
            this.list[order].rcvParts === "" ||
            this.list[order].rcvParts === null ||
            this.list[order].diagCd === "" ||
            this.list[order].diagCd === null ||
            this.list[order].patNm === "" ||
            this.list[order].patNm === null ||
            this.list[order].patBirth === "" ||
            this.list[order].patBirth === null ||
            this.list[order].patTelno === "" ||
            this.list[order].patTelno === null ||
            this.list[order].visitYn === "N"
          ) {
            this.$router.push("/v/request/requestDocument").catch(() => {});
          } else {
            this.$router.push("/v/request/requestView").catch(() => {});
          }
        } else {
          this.$router.push("/v/request/requestView").catch(() => {});
        }
      }
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    async reload() {
      localStorage.setItem("currentTab", 0);
      this.initListStatus();
      this.list = [];
      const list = [];
      this.listStatus.totalDataCnt = 0;
      this.SET_CHILD_LOADER(true);
      const searchOpt = {
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
        condition: this.conditional_condition,
        pickDateFirst: this.conditional_date_array[0],
        pickDateSecond: this.conditional_date_array[1],
      };

      this.searchOpt.receiveOrder = 0;
      try {
        await this.RECEIVE_SEND_LIST_ALL(searchOpt).then(() => {
          //console.log(this.GET_RECEIVE_LIST);
          this.listStatus.totalDataCnt = this.GET_RECEIVE_LIST.param.totalCount;
          for (let i = 0; i < this.GET_RECEIVE_LIST.list.length; i++) {
            list.push(this.GET_RECEIVE_LIST.list[i]);
          }
          this.list = list;
          setTimeout(() => {
            this.SET_CHILD_LOADER(false);
            this.preParePagination();
          }, 800);
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
      this.textName = "의뢰 받은 환자";
      this.textTempName = "";
    },
    openMemo(index) {
      let onOffActiveList = document.querySelectorAll(".receive-patient-list");

      for (let i = 0; i < onOffActiveList.length; i++) {
        if (i !== index) {
          onOffActiveList[i].classList.remove("active");
        }
      }

      if (this.list[index].openMemo === true) {
        this.list[index].openMemo = false;
        onOffActiveList[index].classList.remove("active");
      } else {
        for (let listItem of this.list) {
          listItem.openMemo = false;
        }
        this.list[index].openMemo = true;
        onOffActiveList[index].classList.add("active");
      }
      this.list = [...this.list];
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
@import "@/assets/scss/scrollObj";
.receive-patient-list:hover {
  background-color: #f5f9fa;
}
.receive-patient-list.active {
  background-color: #f5f9fa;
}
.v-text-field--outlined fieldset {
  color: red !important;
}
.fill-width-list {
  width: 100% !important;
}
</style>
